import React from 'react';
import BsToast from 'react-bootstrap/Toast';
import AppContext from '../context/AppContext';

export default class Toast extends React.Component {
  render() {
    // TODO: fix Warning: Failed prop type: Invalid prop `in` of type `string` supplied to `Transition`, expected `boolean`.
    // import ToastFade from 'react-bootstrap/ToastFade';
    return (
      <BsToast className='toast fixed-top' bg={this.props.variant} show={this.props.show} onClose={this.props.onClose}>
        <BsToast.Header className={`content medium ${this.props.titleClass ? this.props.titleClass : ''} pe-3`}>
          <span className={`default-icon material-symbols-rounded ${this.context.font} me-2`}>{this.props.icon}</span>
          <span className={`content font-monospace me-auto ${this.context.font}`}>{this.props.title}</span>
        </BsToast.Header>
        <BsToast.Body className={`content font-monospace text-start ${this.context.font}`}>
          {this.props.message}
        </BsToast.Body>
      </BsToast>
    )
  }
}
Toast.contextType = AppContext;
