import React from 'react';
import FeedbackModal from './FeedbackModal';
import IconButton from './IconButton';

export default class Feedback extends React.Component {
  constructor(){
    super();
    this.state = {
      showModal: false
    };
    this.handleModalHide = this.handleModalHide.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
  }

  handleModalHide() {
    this.setState({showModal: false});
  }

  handleModalShow() {
    this.setState({showModal: true});
  }

  render() {
    return (
      <>
      <FeedbackModal messageId={this.props.messageId} show={this.state.showModal} onHide={this.handleModalHide} />
      <IconButton className='text-info float-end ps-2 medium' icon='feedback' onClick={this.handleModalShow} iconOnly />
      </>
    )
  }
}
