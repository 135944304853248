import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Counter from './Counter';
import ImageInput from './ImageInput';
import Sentiment from './Sentiment';
import TextArea from './TextArea';
import AppContext from '../context/AppContext';

export default class FeedbackModal extends React.Component {
  constructor(){
    super();
    this.state = {
      error: null,
      comments: '',
      image: null,
      sentiment: null,
      isHovered: false
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.maxLength = 512;
  }

  componentDidMount() {
    window.addEventListener('resize', this.scaleText);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.scaleText);
  }

  handleCancel() {
    if (this.imageInput) {
      this.imageInput.cancel();
    }
    this.setState({
      comments: '',
      image: null,
      sentiment: null,
    });
    this.props.onHide();
  }

  handleSend() {
    const feedback = {
      'comments': this.state.comments,
      'sentiment': this.state.sentiment
    };
    if (this.props.messageId) {
      feedback['message_id']  = this.props.messageId;
    }
    this.context.sendFeedback(feedback, this.state.image);
    this.handleCancel();
  }

  handleUpdate(value) {
    this.setState({comments: value.trim()});
    this.commentsBox.scale();
  }

  render() {
    return (
      <Modal centered show={this.props.show} onHide={this.handleCancel}>
        <Modal.Header className='content medium user-select-none' closeButton>
          <Modal.Title className='font-monospace text-info'>
            <span className='default-icon material-symbols-rounded medium align-middle me-2'>feedback</span>
            <span className='align-middle'>Feedback</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Sentiment onSentimentSet={(sent) => this.setState({sentiment: sent})} />
          <TextArea id='comments' label='Comments' maxLength={this.maxLength} onChange={(e) => this.handleUpdate(e.target.value)}
            ref={ref => this.commentsBox = ref} />
          <Counter count={this.state.comments.length} max={this.maxLength} threshold={0.8} />
          <div className='mt-2'></div>
          {this.props.image &&
            <ImageInput imgClass='feedback' rounded thumbnail onImage={(img) => this.setState({image: img})} ref={ref => this.imageInput = ref} />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant={`secondary content ${this.context.font} font-monospace`} onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button variant={`primary content ${this.context.font} font-monospace`} onClick={this.handleSend}
            disabled={(this.state.comments === '') || (!this.state.sentiment) || (this.state.error !== null)} >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
FeedbackModal.contextType = AppContext;
