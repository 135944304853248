import React from 'react';
import Card from 'react-bootstrap/Card';
import Img from 'react-bootstrap/Image'
import Avatar from './Avatar';
import Feedback from './Feedback';
import AppContext from '../context/AppContext';

export default class Bubble extends React.Component {
  constructor(){
    super();
    this.state = { imageLoaded: false };
    this.handleImageLoad = this.handleImageLoad.bind(this);
  }

  componentDidMount() {
    if (this.props.last) {
      this.context.scroll();
    }
    if (this.props.image) {
      this.context.getImage(this.props.image, url => this.setState({image: url}));
    }
  }

  handleImageLoad() {
    this.setState({ imageLoaded: true }, () => {
      this.context.scroll();
    });
  }

  render() {
    var bg;
    switch (this.props.role) {
      case 'system':
        bg = 'info'
        break
      case 'user':
        bg = 'primary'
        break
      case 'assistant':
        bg = 'success'
        break
      case 'error':
        bg = 'danger'
        break
      default:
        bg = 'light'
    }
    var border = 'border-white';
    if (document.documentElement.getAttribute('data-bs-theme') === 'light') {
      border = 'border-black';
    }
    var className = `bg-${bg} ${border} m-1`;
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }
    return (
      <Card className={className}>
        <Card.Body className='message'>
          {(this.props.role === 'assistant' && !this.props.hideFeedback && (!this.props.image || this.state.imageLoaded)) &&
          <div className='float-end'>
            <Feedback messageId={this.props.id} />
          </div>
          }
          {(this.props.role !== 'user' && !this.props.hideAvatar && (!this.props.image || this.props.content || this.state.imageLoaded)) &&
          <Avatar />
          }
          <div className={`bubble content text-light user-select-none ${this.context.font}`}>
            {this.props.content &&
            <div className='font-monospace' dangerouslySetInnerHTML={{__html: this.props.content}}></div>
            }
            {(this.props.image && !this.state.imageLoaded) &&
            <div className={`img-loader ${this.props.content ? 'content' : ''}`}></div>
            }
            {this.state.image &&
            <Img src={this.state.image} className={`pe-none w-100 ${this.props.role !== 'user' ? 'mt-2' : ''} ${this.state.imageLoaded ? '' : 'd-none'}`}
              alt='Not Found' rounded onLoad={this.handleImageLoad} />
            }
          </div>
        </Card.Body>
      </Card>
    );
  }
}
Bubble.contextType = AppContext;
