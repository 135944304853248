import React from 'react';
import Bubble from './Bubble';
import Loader from './Loader';
import AppContext from '../context/AppContext';

export default class Chat extends React.Component {
  render() {
    const items = [];
    for (let i = 0; i < this.context.messages.length; i++) {
      const message = this.context.messages[i];
      var content = message['content'];
      if (content && content.constructor === Object) {
        // Check for the selected language.
        if (this.context.lang in content) {
          content = content[this.context.lang];
        // Then check for the only language.
        } else if (Object.keys(content).length === 1) {
          content = content[Object.keys(content)[0]];
        // Then check for English.
        } else if ('en' in content) {
          content = content['en'];
        // Then check for any language.
        } else if (Object.keys(content).length > 1) {
          content = content[Object.keys(content)[0]];
        } else {
          throw Error('no content available');
        }
      }
      items.push(
        <Bubble
          content={content}
          id={message['id']}
          image={message['image']}
          key={i}
          role={message['role']}
          last={i === this.context.messages.length - 1}
        />
      );
    }
    if (this.context.chatLoading) {
      items.push(<div key={-1} className="chat-loader mx-auto"></div>);
    }
    if (this.context.processError) {
      items.push(<Bubble
        content={'<div>An error occured processing the last message. Please try again.</div>'}
        hideAvatar={true}
        hideFeedback={true}
        id='error'
        key='error'
        role='error'
      />);
    }
    return (
      <div className='chat text-start' id='chat'>
        {items}
        {this.context.userLoading &&
        <Loader role='user' />
        }
        {this.context.apiLoading &&
        <Loader role='assistant' />
        }
      </div>
    );
  }
}
Chat.contextType = AppContext;
