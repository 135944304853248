import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ReCAPTCHA from 'react-google-recaptcha';
import Chat from './components/Chat';
import ConsentModal from './components/ConsentModal';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import Privacy from './components/Privacy';
import NoticeModal from './components/NoticeModal';
import Terms from './components/Terms';
import Toast from './components/Toast';
import AppContext from './context/AppContext';
import * as api from './api';
import './App.css';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    const chatId = localStorage.getItem('chat-id');
    const userId = localStorage.getItem('user-id');
    const flags = JSON.parse(localStorage.getItem('flags'));
    this.state = {
      changeSetting: this.changeSetting.bind(this),
      copyUser: this.copyUser.bind(this),
      deleteMessage: this.deleteMessage.bind(this),
      deleteUser: this.deleteUser.bind(this),
      getMessages: this.getMessages.bind(this),
      processMessages: this.processMessages.bind(this),
      resetStorage: this.resetStorage.bind(this),
      sendFeedback: this.sendFeedback.bind(this),
      sendImage: this.sendImage.bind(this),
      sendMessage: this.sendMessage.bind(this),
      setFlag: this.setFlag.bind(this),
      translateMessages: this.translateMessages.bind(this),
      getImage: (imageName, onSuccess) => api.getImage(imageName, onSuccess),
      scroll: () => window.scrollTo(0, 1000000000),
      setChatId: (id) => localStorage.setItem('chat-id', id),
      setUserId: (id) => localStorage.setItem('user-id', id),
      toggleDevTools: () => {
        const devTools = this.state.flags['devTools'] ? this.state.flags['devTools'] : false;
        this.setFlag('devTools', !devTools)
      },
      togglePromptTools: () => {
        const promptTools = this.state.flags['promptTools'] ? this.state.flags['promptTools'] : false;
        this.setFlag('promptTools', !promptTools)
      },
      apiLoading: false,
      canTranslate: false,
      chatId: chatId,
      chatLoading: false,
      copied: false,
      error: null,
      feedback: false,
      flags: flags ? flags : {},
      font: 'medium',
      lang: 'en',
      messages: [],
      mode: 'auto',
      processError: false,
      translating: false,
      userId: userId,
      userLoading: false
    }
  }

  componentDidMount() {
    const accessCode = localStorage.getItem('access-code');
    if (!accessCode) {
      return;
    }
    if (!this.state.userId) {
      api.initUser(data => {
        localStorage.setItem('user-id', data['user_id']);
        localStorage.setItem('chat-id', data['chat_id']);
        this.setState({
          chatId: data['chat_id'],
          userId: data['user_id']
        }, this.loadUser);
      }, (msg) => {
        this.setState({error: msg});
      });
    } else {
      this.loadUser();
    }
    // Monitor color mode.
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if (this.state.mode === 'auto') {
        const mode = event.matches ? 'dark' : 'light';
        document.documentElement.setAttribute('data-bs-theme', mode);
        this.forceUpdate();
      }
    });
  }

  changeSetting(key, value) {
    api.changeSetting(this.state.userId, key, value, () => {
      this.setState({[key]: value}, () => {
        if (key === 'lang') {
          this.translateCheck();
        }
      });
    }, (msg) => {
      this.setState({error: msg});
    });
  }

  copyUser() {
    api.copyUser(this.state.userId, data => {
      localStorage.setItem('user-id', data['user_id']);
      localStorage.setItem('chat-id', data['chat_id']);
      this.setState({
        chatId: data['chat_id'],
        copied: true,
        userId: data['user_id']
      }, this.loadUser);
    }, msg => {
      this.setState({error: msg});
    });
  }

  deleteMessage() {
    api.deleteMessage(this.state.userId, this.state.chatId, this.state.getMessages, (msg) => {
      this.setState({error: msg});
    });
  }

  deleteUser() {
    api.deleteUser(this.state.userId, () => {
      localStorage.setItem('user-id', 'demo');
      localStorage.setItem('chat-id', 'demo');
      this.setState({
        chatId: 'demo',
        userId: 'demo'
      }, this.loadUser);
    }, (msg) => {
      this.setState({error: msg});
    });
  }

  getMessages(callback) {
    this.setState({
      canTranslate: false,
      chatLoading: true
    }, () => {
      api.getMessages(this.state.userId, this.state.chatId, (data) => {
        if (!data) {
          return;
        }
        this.setState({
          chatLoading: false,
          messages: data
        }, () => {
          this.translateCheck();
          if (callback) {
            callback();
          }
        });
      }, (msg) => {
        this.setState({
          chatLoading: false,
          error: msg
        });
      });
    });
  }

  resetStorage() {
    localStorage.clear();
    window.caches.delete('images');
    api.initUser(data => {
      localStorage.setItem('user-id', data['user_id']);
      localStorage.setItem('chat-id', data['chat_id']);
      this.setState({
        chatId: data['chat_id'],
        userId: data['user_id']
      }, this.loadUser);
    }, (msg) => {
      this.setState({error: msg});
    });
  }

  sendFeedback(feedback, image) {
    feedback['user_id'] = this.state.userId;
    feedback['chat_id'] = this.state.chatId;
    if (image) {
      api.uploadImage(image, (imageName) => {
        feedback['image'] = imageName;
        api.sendFeedback(feedback, () => {
          this.setState({feedback: true});
        }, (msg) => {
          this.setState({error: msg});
        });
      }, (msg) => {
        this.setState({error: msg});
      });
    } else {
      api.sendFeedback(feedback, () => {
        this.setState({feedback: true});
      }, (msg) => {
        this.setState({error: msg});
      });
    }
  }

  sendImage(image) {
    this.setState({userLoading : true}, () => {
      api.uploadImage(image, (imageName) => {
        api.sendImage(this.state.userId, this.state.chatId, imageName, () => {
          this.setState({userLoading : false}, () => {
            this.state.getMessages(this.state.processMessages);
          });
        }, (msg) => {
          this.setState({
            error: msg,
            userLoading : false
          });
        });
      }, (msg) => {
        this.setState({
          error: msg,
          userLoading : false
        });
      });
    });
  }

  sendMessage(message) {
    this.setState({userLoading : true}, ()=> {
      api.sendMessage(this.state.userId, this.state.chatId, message, () => {
        this.setState({userLoading : false}, () => {
          this.state.getMessages(this.state.processMessages);
        });
      }, (msg) => {
        this.setState({
          error: msg,
          userLoading : false
        });
      });
    });
  }

  loadUser() {
    api.getSettings(this.state.userId, (data) => {
      if (!data) {
        return;
      }
      if ('font' in data) {
        this.setState({font: data['font']});
      }
      if ('lang' in data) {
        this.setState({lang: data['lang']});
      }
      if ('mode' in data) {
        this.setState({mode: data['mode']});
      }
    }, (msg) => {
      this.setState({error: msg});
    });
    this.state.getMessages();
  }

  processMessages() {
    this.setState({
      apiLoading : true,
      processError: false
    }, ()=> {
      api.processMessages(this.state.userId, this.state.chatId, () => {
        this.setState({apiLoading : false});
        this.state.getMessages();
      }, () => {
        this.setState({
          apiLoading : false,
          processError: true
        }, this.state.getMessages);
      });
    });
  }

  setFlag(key, val, callback) {
    const flags = this.state.flags;
    if (val === null) {
      delete flags[key];
    } else {
      flags[key] = val;
    }
    localStorage.setItem('flags', JSON.stringify(flags));
    this.setState({flags: flags}, callback);
  }

  translateCheck() {
    const messages = this.state.messages;
    var canTranslate = false;
    for (var i = 0; i < messages.length; i++) {
      if (messages[i]['content'] && (messages[i]['content'].constructor !== Object || !(this.state.lang in messages[i]['content']))) {
        canTranslate = true;
      }
    }
    this.setState({canTranslate: canTranslate});
  }

  translateMessages() {
    this.setState({translating : true}, ()=> {
      api.translateMessages(this.state.userId, this.state.chatId, this.state.lang, () => {
        this.setState({translating : false});
        this.state.getMessages();
      }, (msg) => {
        this.setState({
          error: msg,
          translating : false
        });
      });
    });
  }

  render() {
    // Check for access code in local storage and if none exists render form instead of app.
    if (!localStorage.getItem('access-code')) {
      const update = (code) => {
        localStorage.setItem('access-code', code);
        api.allowAccess(() => {
          window.location.href = '/';
        }, () => {
          localStorage.removeItem('access-code');
          window.location.href = '/';
        });
      }
      const query = new URLSearchParams(window.location.search);
      const accessCode = query.get('access-code');
      if (accessCode) {
        return update(accessCode);
      }
      const body = (
        <>
        <InputGroup className='font-monospace'>
          <InputGroup.Text>
            Access Code
          </InputGroup.Text>
          <Form.Control id='accessCode' onChange={e => this.setState({'accessCode': e.target.value.trim()})} />
        </InputGroup>
        <div className='recaptcha mt-2 mx-auto'>
          <ReCAPTCHA sitekey='6LcOFfkpAAAAAA9AXWYRcUsWpsv_AhXzp5n67T0A' onChange={val => this.setState({captcha: val})} />
        </div>
        </>
      );
      return (
        <AppContext.Provider value={this.state}>
          <NoticeModal body={body} className='overflow-hidden' icon='lock' title='Authorization' okText='Submit' noClose show onOk={() => {
            const input = document.getElementById('accessCode');
            update(input.value);
          }} okDisabled={!this.state.captcha || !this.state.accessCode}/>
        </AppContext.Provider>
      );
    }
    // Set color mode.
    if (this.state.mode === 'auto') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.setAttribute('data-bs-theme', 'dark');
      } else {
        document.documentElement.setAttribute('data-bs-theme', 'light');
      }
    } else {
      document.documentElement.setAttribute('data-bs-theme', this.state.mode);
    }
    return (
      <AppContext.Provider value={this.state}>
        <div className='app'>
          <Toast icon='error_outline' title='Error' message={this.state.error} variant='danger' show={this.state.error}
            onClose={() => this.setState({error: null})} />
          <Toast icon='feedback' title='Feedback' titleClass='text-info' message={`You're feedback has been recieved. Thank you!`}
            show={this.state.feedback} onClose={() => this.setState({feedback: false})} />
           <Toast icon='content_copy' title='Copy' titleClass='text-info' message={`User successfully copied and loaded!`}
            show={this.state.copied} onClose={() => this.setState({copied: false})} />
          <ConsentModal onConsent={() => this.forceUpdate()} />
          <Router>
            <Header />
            <Footer />
            <main>
              <Routes>
                <Route exact path='/' element={<Navigate to='/chat' />} />
                <Route exact path='/chat' element={<Chat />} />
                <Route exact path='/contact' element={<Contact />} />
                <Route exact path='/privacy' element={<Privacy />} />
                <Route exact path='/terms' element={<Terms />} />
              </Routes>
            </main>
          </Router>
        </div>
      </AppContext.Provider>
    );
  }
}
