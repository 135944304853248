import React from 'react';
import AppContext from '../context/AppContext';

export default class TranslateControl extends React.Component {
  render() {
    var variant;
    if (this.props.variant) {
      variant = `btn-outline-${this.props.variant}`;
    } else {
      variant = 'btn-outline-light';
      if (document.documentElement.getAttribute('data-bs-theme') === 'light') {
        variant = 'btn-outline-dark';
      }
    }
    var iconClass = 'icon medium';
    if (this.props.iconClass) {
      iconClass = this.props.iconClass;
    }
    return (
      <button type='button' className={`control btn ${variant} p-1`} onClick={this.context.translateMessages}
        disabled={!this.context.canTranslate || this.context.translating ||  this.context.flags['promptTools']}>
        {this.context.translating && 
        <div className='translate-loader mx-auto'></div>
        }
        {!this.context.translating && 
        <span className={`${iconClass} material-symbols-rounded`}>translate</span>
        }
        <span className={`name font-monospace d-flex justify-content-center large`}>Translate</span>
      </button>
    )
  }
}
TranslateControl.contextType = AppContext;
