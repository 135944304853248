import React from 'react';

export default class Sentiment extends React.Component {
  constructor(){
    super();
    this.state = {
      isPositiveHovered: false,
      isNegativeHovered: false,
      isNeutralHovered: false,
      selected: null
    };
    this.handleHover = this.handleHover.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleHover(type){
    if (type === 'positive') {
      this.setState(prevState => ({
        isPositiveHovered: !prevState.isPositiveHovered
      }));
    } else if (type === 'negative') {
      this.setState(prevState => ({
        isNegativeHovered: !prevState.isNegativeHovered
      }));
    } else if (type === 'neutral') {
      this.setState(prevState => ({
        isNeutralHovered: !prevState.isNeutralHovered
      }));
    }
  }

  handleSelect(selected){
    this.setState({selected: selected});
    this.props.onSentimentSet(selected);
  }

  render() {
    const negClass = this.state.selected === 'negative' ? 'filled text-danger' : this.state.isNegativeHovered ? 'text-danger unfilled' : 'unfilled';
    const neuClass = this.state.selected === 'neutral' ? 'filled text-warning' : this.state.isNeutralHovered ? 'text-warning unfilled' : 'unfilled';
    const posClass = this.state.selected === 'positive' ? 'filled text-success' : this.state.isPositiveHovered ? 'text-success unfilled' : 'unfilled';
    return (
      <div className='d-flex justify-content-between user-select-none mb-3 mx-auto'>
        <span role='button' className={`sentiment material-symbols-rounded ms-auto ${negClass}`}
          onMouseEnter={() => this.handleHover('negative')} onMouseLeave={() => this.handleHover('negative')}
          onClick={() => this.handleSelect('negative')}>
          sentiment_dissatisfied
        </span>
        <span role='button' className={`sentiment material-symbols-rounded me-4 ms-4 ${neuClass}`}
          onMouseEnter={() => this.handleHover('neutral')} onMouseLeave={() => this.handleHover('neutral')}
          onClick={() => this.handleSelect('neutral')}>
          sentiment_neutral
        </span>
        <span role='button' className={`sentiment material-symbols-rounded me-auto ${posClass}`}
          onMouseEnter={() => this.handleHover('positive')} onMouseLeave={() => this.handleHover('positive')}
          onClick={() => this.handleSelect('positive')}>
          sentiment_satisfied
        </span>
      </div>
    )
  }
}
