import React from 'react';
import AppContext from '../context/AppContext';

export default class TextArea extends React.Component {
  constructor() {
    super();
    this.minHeight = 62;
  }

  componentDidMount() {
    const textarea = document.getElementById(this.props.id);
    textarea.style.height = this.minHeight + 'px';
  }

  clear() {
    this.textarea.value = '';
  }

  scale() {
    // TODO: remove this flagged disable after missing footer bug is fixed.
    if (this.context.flags['disableScale'] === 'true') {
      console.log(`scale disabled`);
      return;
    }
    const textarea = document.getElementById(this.props.id);
    // TODO: remove this logging after missing footer bug is fixed.
    if (this.context.flags['logScroll'] === 'true') {
      if (this.props.id === 'message') {
        if (textarea && textarea.value === '') {
          console.log(`emtpy message.scrollHeight: ${textarea.scrollHeight}`);
        }
        console.log(`message.style.height: ${textarea.style.height}`);
        console.log(`message.scrollHeight: ${textarea.scrollHeight}`);
      }
    }
    var oneLineHeight = 86;
    if (this.context) {
      // The following values need to be updated when --content-font is changed in App.css.
      switch (this.context.font) {
        case 'large':
          oneLineHeight = 99;
          break;
        default:
        case 'medium':
          oneLineHeight = 86;
          break;
        case 'small':
          oneLineHeight = 76;
          break;
      }
    }
    if (textarea) {
      if (textarea.value === '' || textarea.scrollHeight < oneLineHeight) {
        textarea.style.height = this.minHeight + 'px';
      } else {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      }
      textarea.style.overflowY = 'hidden';
    }
  }

  render() {
    return (
      <div className='form-floating'>
        <textarea className={`${this.props.className} content form-control ${this.context.font}`}  id={this.props.id} name={this.props.id}
          maxLength={this.props.maxLength} onFocus={this.props.onFocus} onChange={this.props.onChange} ref={ref => this.textarea = ref}></textarea>
        <label htmlFor={this.props.id}>{this.props.label}</label>
      </div>
    )
  }
}
TextArea.contextType = AppContext;
