import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Img from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ControlGroup from './ControlGroup';
import DevControlGroup from './DevControlGroup';
import NoticeModal from './NoticeModal';
import PromptControlGroup from './PromptControlGroup';

import icon from '../img/icon.svg';
import AppContext from '../context/AppContext';

export default class Header extends React.Component {
  constructor(){
    super();
    this.state = {showNotice: false};
  }

  render() {
    const hasConsent = localStorage.getItem('consent') === 'agree';
    const alphaNotice = 'This application is currently in its alpha stage of development. You may encounter bugs, incomplete features, and frequent updates as we work to improve the service. Your feedback is invaluable to us during this phase.';
    //const betaNotice = 'This application is currently in its beta stage of development. While it is more stable and feature-complete, you may still encounter some bugs and improvements being made. Your feedback is invaluable to us during this phase to help us refine the service.';
    return (
      <Navbar fixed='top' expand='false' className='bg-body-tertiary'>
        <Container fluid>
          <Navbar.Brand className='d-inline-flex align-items-center'>
            <Link to='/'>
              <Img src={icon} width='48' height='44' className='icon d-inline-block align-top' alt='Home'/>
            </Link>
            <span className={'font-monospace text-success user-select-none h3 mb-0 px-2'}>FlavorTrace</span>
            <h6 className='mt-2' >
              <div role='button' className='badge rounded-pill bg-danger text-light' onClick={() => this.setState({showNotice: true})}>
                <span className='user-select-none'>αlpha{/* βeta */}</span>
              </div>
              <NoticeModal body={alphaNotice} icon='deployed_code' title='Alpha Release' show={this.state.showNotice} variant='danger'
                onHide={() => this.setState({showNotice: false})} onOk={() => this.setState({showNotice: false})} />
            </h6>
          </Navbar.Brand>
          <div className='row row-cols-2 me-1'>
            <div className='col col-sm-6 p-0'>
              <Navbar.Toggle aria-controls='offcanvasNavbar-expand' style={hasConsent ? {} : {display: 'none'}} />
            </div>
          </div>
          <Navbar.Offcanvas id='offcanvasNavbar-expand' aria-labelledby='offcanvasNavbarLabel-expand' placement='end'>
            <Offcanvas.Header closeButton className='content medium p-0 px-2 pt-3' />
            <Offcanvas.Body className='d-flex flex-column justify-content-between'>
              <ControlGroup />
              <PromptControlGroup />
              <DevControlGroup />
              <div className={`copyright gap-2 hstack font-monospace user-select-none ${this.context.font}`}>
                <div className='text-nowrap ms-auto'>
                  © 2024 <a role='button' className='text-decoration-none' href='/contact'>FlavorTrace</a>
                </div>
                <div className='vr'></div>
                <a role='button' className='text-decoration-none' href='/terms'>Terms</a>
                <div className='vr'></div>
                <a role='button' className='text-decoration-none me-auto' href='/privacy'>Privacy</a>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    )
  }
}
Header.contextType = AppContext;
