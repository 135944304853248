import React from 'react';
import AppContext from '../context/AppContext';

export default class ScrollButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      showFooter: false,
      showHeader: false,
      showScroll: false
    };
    this.scrolling = this.scrolling.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrolling);
  }

  scrolling() {
    const scrollHeight = document.body.scrollHeight - window.innerHeight;
    const scrollRatio = (window.scrollY / scrollHeight);
    if (this.context.flags['logScroll'] === 'true') {
      console.log('scrollRatio', scrollRatio);
    }
    const showScroll = this.props.reverse ? window.scrollY !== 0 : scrollRatio < 0.999;
    const opacity = this.props.reverse ? 0.3 + scrollRatio : 1.3 - scrollRatio;
    this.setState({
      opacity : opacity,
      showFooter: window.scrollY >= 0 && scrollRatio < 0.999,
      showHeader: window.scrollY !== 0,
      showScroll: showScroll
    });
  }

  render() {
    var opacity = this.state.opacity;
    if (this.button) {
      // Prevent scroll button from overlapping header.
      const rect = this.button.getBoundingClientRect();
      if (rect.y < 200) {
        opacity = 0;
      }
    }
    if (!this.state.showScroll) {
      opacity = 0;
    }
    var color = this.props.reverse ? 'text-light' : 'text-dark';
    if (document.documentElement.getAttribute('data-bs-theme') === 'light') {
      color = this.props.reverse ? 'text-dark' : 'text-light';
    }
    return (
      <div>
        <div id='scroll-btn' role='button' className={`scroll-btn mx-auto ${color} ${this.props.reverse ? 'reverse' : ''} ${opacity === 0 ? 'pe-none' : ''}`}
          onClick={() => {
            if (this.props.reverse) {
              window.scrollTo(0, 0);
            } else {
              this.context.scroll()
            }
          }}
          ref={ref => this.button = ref} style={{opacity: opacity}}>
          <span className='scroll-icon material-symbols-outlined'>arrow_circle_down</span>
        </div>
        <div className={`fixed-top header-fade ${this.state.showHeader ? '' : 'd-none'}`}></div>
        <div className={`footer-fade ${this.state.showFooter ? '' : 'd-none'}`}></div>
      </div>
    )
  }
}
ScrollButton.contextType = AppContext;