import React from 'react';
import Bubble from './Bubble';
import AppContext from '../context/AppContext';

export default class Loader extends React.Component {
  componentDidMount() {
    this.context.scroll();
  }

  render() {
    var className='loader-bubble mt-0';
    if (this.props.role === 'user') {
      className += ` ms-auto`;
    }
    return (
      <Bubble
        className={className}
        content={'<div class="loader"></div>'}
        hideAvatar={true}
        hideFeedback={true}
        id='loader'
        key='loader'
        role={this.props.role}
      />
    )
  }
}
Loader.contextType = AppContext;
