import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import AppContext from '../context/AppContext';

// Name, Name in Lang, Code
const languages = [
  ['English', 'English', 'en'],
  ['Spanish', 'Español', 'es'],
  ['Chinese', '中文', 'zh'],
  ['Filipino', 'Filipino', 'tl'],
  ['Vietnamese', 'Tiếng Việt', 'vi'],
  ['French', 'Français', 'fr'],
  ['Korean', '한국어', 'ko'],
  ['Arabic', 'العربية', 'ar'],
  ['Russian', 'Русский', 'ru'],
  ['Portuguese', 'Português', 'pt']
];

export default class LangControl extends React.Component {
  constructor(){
    super();
    this.state = {
      clicks: 0,
      firstClick: null
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    // If 8 clicks in 3 seconds then toggle prompt tools.
    if (!this.state.firstClick) {
      this.setState({
        clicks: 1,
        firstClick: Date.now()
      });
    } else if (Date.now() - this.state.firstClick < 3000) { // 3 seconds
      this.setState({
        clicks: this.state.clicks + 1,
      }, () => {
        if (this.state.clicks === 8) { // 8 clicks
          this.context.togglePromptTools();
          this.setState({
            clicks: 0,
            firstClick: null
          });
        }
      });
    } else {
      // Reset
      this.setState({
        clicks: 0,
        firstClick: null
      });
    }
  }

  render() {
    var lang;
    for (var i = 0; i < languages.length; i++) {
      if (languages[i][2] === this.context.lang) {
        lang = languages[i];
        break;
      }
    }
    const items = [];
    for (let i = 0; i < languages.length; i++) {
      const l = languages[i];
      items.push(
        <Dropdown.Item className='font-monospace' key={l[2]} onClick={() => this.context.changeSetting('lang', l[2])}
          disabled={l === lang || this.context.flags['promptTools']}>
          {l[1]}
        </Dropdown.Item>
      );
    }
    var variant = 'outline-light';
    if (document.documentElement.getAttribute('data-bs-theme') === 'light') {
      variant = 'outline-dark';
    }
    return (
      <Dropdown className='btn-group container p-0'>
        <Dropdown.Toggle variant={`${variant} control p-1`} disabled={this.context.translating}
          onClickCapture={this.handleClick}>
          <span className={`icon material-symbols-rounded medium`}>language</span>
          <div className={`name font-monospace d-flex justify-content-center large`}>{lang[1]}</div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {items}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
LangControl.contextType = AppContext;
