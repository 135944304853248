import React from 'react';
import Control from './Control';
import AppContext from '../context/AppContext';

export default class ModeControl extends React.Component {
  render() {
    var btn;
    const context = this.context;
    if (context.mode === 'dark') {
      btn = <Control iconClass='mode-dark' name='Dark' icon='clear_night'
        onClick={() => this.context.changeSetting('mode', 'light')} />
    } else if (context.mode === 'light') {
      btn = <Control name='Light' icon='clear_day' onClick={() => this.context.changeSetting('mode', 'auto')} />
    } else {
      btn = <Control iconClass='mode-auto' name='Auto' icon='routine'
        onClick={() => this.context.changeSetting('mode', 'dark')} />
    }
    return (
      <>
      {btn}
      </>
    )
  }
}
ModeControl.contextType = AppContext;
