
import React from 'react';
import { useLocation } from 'react-router-dom';
import IconButton from './IconButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ImageModal from './ImageModal';
import Counter from './Counter';
import AppContext from '../context/AppContext';
import TextArea from './TextArea';
import ScrollButton from './ScrollButton';

export class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      showModal: false
    };
    this.handleModalHide = this.handleModalHide.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.maxLength = 512;
  }

  createResize() {
    var lastHeight = window.innerHeight;
    var lastWidth = window.innerWidth;
    return () => {
      if (window.innerHeight === lastHeight && window.innerWidth === lastWidth) {
        console.log('no change');
      } else {
        this.scaleTextAndPadMain();
      }
      lastHeight = window.innerHeight;
      lastWidth = window.innerWidth;
    }
  }

  componentDidMount() {
    window.addEventListener('load', this.scaleTextAndPadMain);
    window.addEventListener('resize', this.createResize());
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.scaleTextAndPadMain);
    window.removeEventListener('resize', this.createResize());
  }

  componentDidUpdate() {
    this.scaleTextAndPadMain();
  }

  handleHover(){
    this.setState(prevState => ({
        isHovered: !prevState.isHovered
    }));
  }

  handleModalHide() {
    this.setState({showModal: false});
  }

  handleModalShow() {
    this.setState({showModal: true});
  }

  handleSend() {
    this.context.sendMessage(this.state.message);
    this.messageBox.clear();
    this.handleUpdate('');
  }

  handleUpdate(value) {
    this.setState({message: value.trim()});
    this.scaleTextAndPadMain();
  }

  scaleTextAndPadMain() {
    if (this.messageBox) {
      this.messageBox.scale();
    }
    // Pad main element.
    const chat = document.getElementById('chat');
    if (chat) {
      // TODO: remove logging after footer not showing in browser bug is fixed.
      //console.log(`chat.scrollHeight: ${chat.scrollHeight}`);
      const footer = document.getElementById('footerDiv');
      //console.log(`footer.scrollHeight: ${footer.scrollHeight}`)
      chat.style.paddingBottom = (footer.scrollHeight - 4) + 'px';
    }
  }

  render() {
    const pathname = this.props.location.pathname;
    if (pathname !== '/chat') {
      return <div></div>;
    }
    var border = 'border-white';
    if (document.documentElement.getAttribute('data-bs-theme') === 'light') {
      border = 'border-black';
    }
    return (
      <>
      <ImageModal show={this.state.showModal} onHide={this.handleModalHide} />
      <div className='fixed-bottom' >
        <ScrollButton />
        <div className='bg-body-tertiary pb-2' id='footerDiv'>
          <InputGroup className='pt-1 ps-1 pe-1'>
            <IconButton icon='image' iconClass='mt-2 medium' id='button-image' variant={`primary ${border}`}
              disabled={this.state.message !== ''} onClick={this.handleModalShow} />
            <TextArea id='message' className={border} label='Message' maxLength={this.maxLength}
              onChange={(e) => this.handleUpdate(e.target.value)} ref={ref => this.messageBox = ref} />
            <IconButton icon='send' iconClass='mt-2 medium' id='button-send' variant={`primary ${border}`}
              disabled={this.state.message === ''} onClick={this.handleSend} />
          </InputGroup>
          <Counter count={this.state.message.length} max={this.maxLength} threshold={0.8} className='message' />
        </div>
      </div>
      </>
    )
  }
}
Footer.contextType = AppContext;

const withLocation = Component => props => {
  const location = useLocation();
  return <Component {...props} location={location} />;
};

export default withLocation(Footer);
