import React from 'react';
import Button from 'react-bootstrap/Button';
import AppContext from '../context/AppContext';

export default class Contact extends React.Component {
  render() {
    return (
      <div className={`terms content font-monospace user-select-none ${this.context.font}`}>
        <h2 className='text-center ms-4 pt-1'>
          Contact Us
          <Button as='a' href='/' variant={`primary content ${this.context.font} font-monospace`} className='float-end me-2'>
            Home
          </Button>
        </h2>
        <div className={`content text-start pe-3 ps-3 ${this.context.font}`}>
          Need assistance or have questions? Contact us at <b className='text-info'>flavortrace@gmail.com</b>. Our team will respond as soon as possible. Thank you for your patience!
        </div>
      </div>
    );
  }
}
Contact.contextType = AppContext;