import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import AppContext from '../context/AppContext';

export default class NoticeModal extends React.Component {
  render() {
    const variant = this.props.variant ? this.props.variant : 'info';
    return (
      <Modal show={this.props.show} centered onHide={this.props.onHide}>
         <Modal.Header className='content medium user-select-none' closeButton={!this.props.noClose}>
          <Modal.Title className={`font-monospace text-${variant}`}>
            <span className='default-icon material-symbols-rounded medium align-middle me-2'>{this.props.icon}</span>
            <span className='align-middle'>{this.props.title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${this.props.className ? this.props.className : ''} content text-justify user-select-none  ${this.context.font}`}>
          {this.props.body}
        </Modal.Body>
        <Modal.Footer>
          {(this.props.cancel || this.props.cancelText) &&
          <Button variant={`${this.props.cancelVariant ? this.props.cancelVariant: 'secondary'} content ${this.context.font} font-monospace`}
            onClick={this.props.onCancel ? this.props.onCancel : this.props.onHide} disabled={this.props.cancelDisabled}>
            {this.props.cancelText ? this.props.cancelText : 'Cancel'}
          </Button>
          }
          <Button variant={`primary content ${this.context.font} font-monospace`} onClick={this.props.onOk} disabled={this.props.okDisabled}>
            {this.props.okText ? this.props.okText : 'OK'}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
NoticeModal.contextType = AppContext;
