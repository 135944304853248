import React from 'react';
import Img from 'react-bootstrap/Image'
import AppContext from '../context/AppContext';

export default class ImageInput extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      image: null,
      imageUrl: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  cancel() {
    URL.revokeObjectURL(this.state.imageUrl);
    this.setState({
      error: null,
      image: null,
      imageUrl: null
    });
  }

  handleError(err) {
    this.setState({
      error: err,
      image: null,
      imageUrl: null
    });
    this.props.onImage(null);
  }

  handleChange(e) {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!img.type.startsWith('image/')) {
        this.handleError(`${img.type} is not a valid image type.`);
      } else {
        this.setState({
          error: null,
          image: img,
          imageUrl: URL.createObjectURL(img)
        });
        this.props.onImage(img);
      }
    } else {
      this.cancel();
    }
  }

  render() {
    var size;
    if (this.context.font === 'small') {
      size = 'form-control-sm';
    } else if (this.context.font === 'large') {
      size = 'form-control-lg';
    }
    return (
      <>
      <input className={`form-control ${size} font-monospace`} type='file' accept='image/*;capture=camera' onChange={this.handleChange} />
      {this.state.error && (
        <div className='text-danger user-select-none mt-1'>
          <span className={`default-icon align-middle material-synbols-outlined pe-1 ${this.context.font}`}>error_outline</span>
          <span className={`content align-middle font-monospace ${this.context.font}`}>Error: {this.state.error}</span>
        </div>
      )}
      {(this.state.imageUrl && !this.state.error) && (
        <Img src={this.state.imageUrl} className={`d-block ${this.props.imgClass} pe-none mx-auto mt-2`} onError={() => this.handleError('image could not be loaded.')}
          rounded={this.props.rounded} thumbnail={this.props.thumbnail} />
      )}
      </>
    );
  }
}
ImageInput.contextType = AppContext;
