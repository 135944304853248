import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Control from './Control';
import AppContext from '../context/AppContext';

export default class PromptControlGroup extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className={`tools prompt mt-auto ${this.context.flags['promptTools'] ? '' : 'd-none'}`}>
        <div className='title font-monospace text-warning'>
          Prompt Tools
        </div>
        <ButtonGroup className='d-flex justify-content-center'>
          <Control name='Add' icon='note_add' variant='warning'  onClick={() => this.setState({showId: true, whichId: 'userId'})} disabled />
          <Control name='Edit' icon='edit_document' variant='warning' onClick={() => this.setState({showId: true, whichId: 'chatId'})} disabled />
          <Control name='Copy' icon='file_copy' variant='warning'  onClick={() => this.setState({showFlags: true})} disabled />
          <Control name='Delete' icon='scan_delete' variant='warning' onClick={() => this.setState({showReset: true})} disabled />
        </ButtonGroup>
      </div>
    )
  }
}
PromptControlGroup.contextType = AppContext;
