import React from 'react';
import Button from 'react-bootstrap/Button';
import AppContext from '../context/AppContext';
import ScrollButton from './ScrollButton';

export default class Privacy extends React.Component {
  render() {
    return (
      <>
      <div className='fixed-bottom'>
        <ScrollButton reverse />
      </div>
      <div className={`terms content font-monospace user-select-none ${this.context.font}`}>
        <h2 className='text-center ms-4 pt-1'>
          Privacy Policy
          <Button as='a' href='/' variant={`primary content ${this.context.font} font-monospace ms-2`} className='float-end me-2'>
            Home
          </Button>
        </h2>
        <div className={`content text-start pe-3 ps-3 ${this.context.font}`}>
        <h4>Introduction</h4>
        This Privacy Policy explains how FlavorTrace ("the Service") collects, uses, and protects your information. The Service is operated by the developers of FlavorTrace ("the Developers").
        <h4 className='pt-2'>Data We Collect</h4>
        We collect the following types of data:
        <ul>
          <li><b>Personal Information</b>: Information that identifies you as an individual, such as your name, email address, and phone number.</li>
          <li><b>Usage Data</b>: Information about how you use the Service, including chat messages, images, and other content you share.</li>
        </ul>
        <h4>Use of Data</h4>
        We use the collected data for the following purposes:
        <ul>
          <li>To provide and maintain the Service.</li>
          <li>To improve and personalize user experience.</li>
          <li>To communicate with you about updates and other relevant information.</li>
        </ul>
        <h4>Use of Data for AI and Data Analytics</h4>
        We may collect and use your chat data, including text, images, and other content, to train and improve our artificial intelligence (AI) systems and data analytics models. This helps us enhance the functionality and performance of the Service. Additionally, we may license anonymized data sets to third-party companies for their own AI and data analytics purposes.
        <ul>
          <li><b>Data Collected</b>: We may collect any content you share within the Service, including but not limited to text messages, images, and other media files.</li>
          <li><b>Purpose of Collection</b>: The data is used to train AI systems and data analytics models that improve the Service’s features, such as enhancing chat functionalities, improving content moderation, and personalizing user experiences. Anonymized data sets may be licensed to third parties for similar purposes.</li>
          <li><b>Data Protection</b>: We take measures to anonymize and secure the data used for AI and data analytics purposes to protect your privacy. Personal identifiers are removed or encrypted to prevent the data from being linked back to individual users.</li>
          <li><b>Data Licensing</b>: Anonymized data sets may be shared with third-party companies under licensing agreements to help them train and improve their own AI and data analytics models.</li>
          <li><b>User Control</b>: You have the right to opt-out of having your data used for AI and data analytics purposes. To exercise this right, please contact us at <b className='text-info'>flavortrace@gmail.com</b> or adjust your settings within the Service.</li>
        </ul>
        <h4>Interaction with AI Chatbot</h4>
        When you interact with the AI chatbot, we collect and process your chat data to provide responses and improve the chatbot's performance. You will be required to explicitly consent to these interactions. This data is also used for training and enhancing our AI systems. Please note that the AI chatbot may make mistakes. Always use your judgment and seek professional advice if needed.
        <h4 className='pt-2'>Data Retention</h4>
        We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected or as required by law. Our data retention periods are as follows:
        <ul>
          <li><b>Chat Data</b>: Retained for up to 12 months after the last interaction.</li>
          <li><b>Account Information</b>: Retained for the duration of your account and for up to 6 months after account deletion.</li>
          <li><b>Anonymized Data</b>: Retained indefinitely for research and development purposes.</li>
        </ul>
        <h4>Data Security</h4>
        We implement appropriate security measures to protect your data against unauthorized access, alteration, disclosure, or destruction. These measures include encryption of data in transit and at rest, access controls, and regular security audits.
        <h4 className='pt-2'>International Data Transfers</h4>
        If you are accessing the Service from outside the United States, your data may be transferred to and processed in the United States. We comply with applicable legal requirements for transferring personal data to countries outside of your home country and ensure adequate protection for your data.
        <h4 className='pt-2'>Automated Decision-Making</h4>
        Our AI systems may make automated decisions based on the data you provide. These decisions can include generating responses in chat interactions, recommending content, or moderating content.
        <h5 className='text-secondary pt-1'>Transparency and Information</h5>
        <ul>
          <li><b>General Information</b>: Our AI chatbots and systems are designed to enhance user experience by providing automated responses and recommendations. The AI analyzes patterns in user interactions to improve its responses and functionalities.</li>
          <li><b>Data Usage</b>: The AI uses data such as chat history, user preferences, and interaction patterns to make decisions. This data helps personalize responses and improve the accuracy of the chatbot.</li>
        </ul>
        <h5 className='text-secondary'>Limitations and User Rights</h5>
        <ul>
          <li><b>Complexity and Constraints</b>: Due to the complexity of our AI models and system architecture, it may not be possible to explain the detailed logic of each automated decision or to intervene in real-time decision-making processes.</li>
          <li><b>User Support</b>: If you have concerns or questions about an automated decision, you can contact our support team at <b className='text-info'>flavortrace@gmail.com</b>. Our team will provide general information about how the AI system works and assist with your concerns as much as possible.</li>
          <li><b>Fallback Options</b>: In cases where automated decisions significantly impact your experience, we offer the option to escalate your concerns to a human representative for further review and assistance.</li>
        </ul>
        <h5 className='text-secondary'>Rights Under GDPR and CCPA</h5>
        <ul>
          <li><b>GDPR</b>: Under the General Data Protection Regulation (GDPR), you have the right to request information about the logic involved in automated decision-making and to object to certain decisions. While we strive to provide transparency, please note the limitations mentioned above.</li>
          <li><b>CCPA</b>: Under the California Consumer Privacy Act (CCPA), you have the right to request information about the use of your data in automated decision-making. We will provide general information about our AI systems and data usage practices.</li>
        </ul>
        <h4>Exercising Your Rights</h4>
        To exercise your rights to access, correct, delete, or export your data, or to object to or restrict the processing of your data, please contact us at <b className='text-info'>flavortrace@gmail.com</b>. We will acknowledge your request within 10 days and provide a substantive response within 30 days. If your request is complex or we are handling numerous requests, we may extend our response time to 60 or 90 days. We will inform you of any extension within the initial 30-day period.
        <h4 className='pt-2'>Children's Privacy</h4>
        The Service is not intended for use by children under the age of 13. If we become aware that we have collected personal data from a child under 13 without parental consent, we will take steps to delete that information.
        <ul>
          <li><b>Parental Consent</b>: For children under the age of 13, parental consent is required for the collection and use of personal data.</li>
          <li><b>Data Collected from Children</b>: We may collect personal information from children such as name, age, and contact details with parental consent.</li>
          <li><b>Usage of Data</b>: The data collected from children is used solely for providing and improving the Service's services and not for AI or data analytics purposes without explicit parental consent.</li>
          <li><b>Parental Rights</b>: Parents can review, delete, or refuse the further collection of their child’s personal information by contacting us at <b className='text-info'>flavortrace@gmail.com</b>.</li>
        </ul>
        <h4>Changes to This Privacy Policy</h4>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Service. Your continued use of the Service after any changes constitutes your acceptance of the updated policy.
        <h4 className='pt-2'>Governing Law</h4>
        This Privacy Policy is governed by and construed in accordance with the laws of the United States. Any disputes arising from this Privacy Policy will be resolved in the courts of the United States.
        <h4 className='pt-2'>Contact Information</h4>
        If you have any questions about this Privacy Policy, please contact us at <b className='text-info'>flavortrace@gmail.com</b>.
        </div>
      </div>
      </>
    )
  }
}
Privacy.contextType = AppContext;
