import React from 'react';
import Button from 'react-bootstrap/Button';

export default class IconButton extends React.Component {
  constructor(){
    super();
    this.state = { isHovered: false };
    this.handleHover = this.handleHover.bind(this);
  }

  handleHover(){
    this.setState(prevState => ({
      isHovered: !prevState.isHovered
    }));
  }

  render() {
    if (this.props.iconOnly) {
      return (
        <span role='button' className={`default-icon material-symbols-outlined ${this.state.isHovered ? 'filled' : 'c'} ${this.props.className}`} 
          onClick={this.props.onClick} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
          {this.props.icon}
        </span>
      );
    }
    return (
      <Button className={`btn-${this.props.variant}`} type='button' id={this.props.id} disabled={this.props.disabled}
        onClick={this.props.onClick} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
        <span className={`default-icon material-symbols-outlined ${this.state.isHovered ? 'filled' : 'unfilled'} ${this.props.iconClass}`}>{this.props.icon}</span>
      </Button>
    );
  }
}
