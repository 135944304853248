import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import AppContext from '../context/AppContext';

export default class ConsentModal extends React.Component {
  constructor(){
    super();
    const consent = localStorage.getItem('consent');
    const location = window.location.pathname;
    this.state = {
      follow_up: consent === 'disagree',
      show: location !== '/contact' && location !== '/privacy' && location !== '/terms' && (consent === null || consent === 'disagree')
    };
    this.handleAgree = this.handleAgree.bind(this);
    this.handleDisagree = this.handleDisagree.bind(this);
    this.handleProvide = this.handleProvide.bind(this);
  }

  handleAgree() {
    localStorage.setItem('consent', 'agree');
    this.setState({show: false});
    this.props.onConsent();
  }

  handleDisagree(){
    localStorage.setItem('consent', 'disagree');
    this.setState({follow_up: true});
  }

  handleProvide(){
    localStorage.removeItem('consent');
    this.setState({follow_up: false});
  }

  render() {
    return (
      <Modal show={this.state.show}  centered>
        <Modal.Header className='content medium user-select-none'>
          <Modal.Title className='font-monospace text-danger'>
            <span className='default-icon material-symbols-rounded medium align-middle me-2'>check_circle</span>
            <span className='align-middle'>Consent Required</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='content text-justify user-select-none medium'>
          {!this.state.follow_up && (
            <div>
              <div className='mb-3'>
                <b>Welcome to FlavorTrace!</b>
              </div>
              <div className='mb-3'>
                By using this app, you acknowledge that you are interacting with an AI chatbot, which collects and processes your chat data to provide responses and improve our services. This data may be anonymized and shared with third parties. The chatbot may make mistakes, so use your judgment and seek professional advice if needed.
              </div>
              <div className='mb-3'>
                Review our <a href='/terms'>Terms of Use</a> and <a href='/privacy'>Privacy Policy</a> for details on data usage and protection. <a href='/contact'>Contact</a> us with any questions.
              </div>
              <div>
                <b>Do you agree to these terms and consent to interact with the AI chatbot?</b>
              </div>
            </div>
          )}
          {this.state.follow_up && (
            <div>
              <div className='mb-3'>
                We respect your decision. However, as chat is the primary feature of this app, you will not be able to use the app without consenting to interact with the AI chatbot and allowing us to collect and process your chat data.
              </div>
              <div>
                If you change your mind, you can provide your consent to start using the app.
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!this.state.follow_up && (
            <>
            <Button variant={`primary content medium font-monospace`} onClick={this.handleAgree}>
              Agree
            </Button>
            <Button variant={`secondary content medium font-monospace`} onClick={this.handleDisagree}>
              Disagree
            </Button>
            </>
          )}
          {this.state.follow_up && (
            <Button variant={`primary content medium font-monospace`} onClick={this.handleProvide}>
              Provide Consent
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}
ConsentModal.contextType = AppContext;
