import {v4 as uuid} from 'uuid';

function apiDomain() {
  const production = process.env.NODE_ENV === 'production';
  return production ? 'https://api.flavortrace.com' : 'http://127.0.0.1:7071';
}

function apiRequest(body = null, method = 'GET', contentType = 'application/json', addHeaders={}) {
  const accessCode = localStorage.getItem('access-code');
  const headers = {
    'Accept': contentType,
    'Content-Type': contentType,
    'X-Access-Code': accessCode
  }
  for (var k in addHeaders) {
    headers[k] = addHeaders[k];
  }
  const req = {
    method: method,
    headers: headers
  };
  req['body'] = body;
  return req;
}

function callApi(url, req, onSuccess, onError, jsonReturn = false) {
  fetch(url, req)
    .then(resp => {
      if (!resp.ok) {
        throw new Error(`request failed (${resp.status}): ${resp.statusText}`);
      }
      if (jsonReturn) {
        return resp.json();
      }
      return resp.text();
    }).catch(err => {
      console.error(`calling api ${url} ${err}`)
      onError(err);
      return Promise.reject(err);
    }).then(data => {
      onSuccess(data);
    }, () => {}); // The error is handled in the catch above so ignore here.
}

export function allowAccess(onSuccess, onError) {
  const url = `${apiDomain()}/api/allow`;
  const req = apiRequest(null, 'POST');
  callApi(url, req, onSuccess, (err) => {
    const msg = `checking access: ${err}`;
    console.error(msg);
    onError(msg);
  });
}

export function changeSetting(userId, key, value, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}/settings`;
  const req = apiRequest(JSON.stringify({[key]: value}), 'POST');
  callApi(url, req, onSuccess, (err) => {
    const msg = `updating setting '${key}' = '${value}': ${err}`;
    console.error(msg);
    onError(msg);
  });
}

export function copyUser(userId, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}/copy`;
  const req = apiRequest(null, 'POST');
  callApi(url, req, onSuccess, (err) => {
    const msg = `copying user: ${err}`;
    console.error(msg);
    onError(msg);
  }, true);
}

export function deleteMessage(userId, chatId, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}/chat/${chatId}`;
  const req = apiRequest(null, 'DELETE');
  callApi(url, req, onSuccess, (err) => {
    const msg = `deleting message: ${err}`;
    console.error(msg);
    onError(msg);
  });
}

export function deleteUser(userId, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}`;
  const req = apiRequest(null, 'DELETE');
  callApi(url, req, onSuccess, (err) => {
    const msg = `deleting user: ${err}`;
    console.error(msg);
    onError(msg);
  });
}

export function getImage(imageName, onSuccess) {
  const url = `${apiDomain()}/api/image/${imageName}`;
  window.caches.open('images')
    .then(cache => cache.match(url))
    .then(async r => {
      if (r) {
        return r.blob()
      }
      // Not found in cache.
      const ext = imageName.substring(imageName.indexOf('.') + 1);
      const contentType = `image/${ext}`;
      const req = apiRequest(null, 'GET', contentType);
      const res = await fetch(url, req);
      // Cache image.
      const temp = res.clone();
      window.caches.open('images')
        .then(cache => {
          cache.put(url, temp);
        });
      return await res.blob();
    }).then(blob => URL.createObjectURL(blob))
      .then(img => onSuccess(img));
}

export function getMessages(userId, chatId, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}/chat/${chatId}`;
  const req = apiRequest();
  callApi(url, req, onSuccess, (err) => {
    const msg = `getting messages: ${err}`;
    console.error(msg);
    onError(msg);
  }, true);
}

export function getSettings(userId, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}/settings`;
  const req = apiRequest();
  callApi(url, req, onSuccess, (err) => {
    const msg = `getting settings: ${err}`;
    console.error(msg);
    onError(msg);
  }, true);
}

export function initUser(onSuccess, onError) {
  const url = `${apiDomain()}/api/user`;
  const req = apiRequest(null, 'POST');
  callApi(url, req, onSuccess, (err) => {
    const msg = `initializing user: ${err}`;
    console.error(msg);
    onError(msg);
  }, true);
}

export function processMessages(userId, chatId, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}/chat/${chatId}`;
  const req = apiRequest(null, 'PATCH');
  callApi(url, req, onSuccess, (err) => {
    const msg = `processing messages: ${err}`;
    console.error(msg);
    onError(msg);
  });
}

export function sendFeedback(feedback, onSuccess, onError) {
  const url = `${apiDomain()}/api/feedback`;
  const req = apiRequest(JSON.stringify(feedback), 'POST');
  callApi(url, req, onSuccess, (err) => {
    const msg = `sending feedback: ${err}`;
    console.error(msg);
    onError(msg);
  });
}

export function sendImage(userId, chatId, image, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}/chat/${chatId}`;
  const req = apiRequest(JSON.stringify({'image': image, 'role': 'user'}), 'POST');
  callApi(url, req, onSuccess, (err) => {
    const msg = `sending image: ${err}`;
    console.error(msg);
    onError(msg);
  });
}

export function sendMessage(userId, chatId, message, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}/chat/${chatId}`;
  const req = apiRequest(JSON.stringify({'content': message, 'role': 'user'}), 'POST');
  callApi(url, req, onSuccess, (err) => {
    const msg = `sending message: ${err}`;
    console.error(msg);
    onError(msg);
  });
}

export function translateMessages(userId, chatId, lang, onSuccess, onError) {
  const url = `${apiDomain()}/api/user/${userId}/chat/${chatId}/translate`;
  const req = apiRequest(JSON.stringify({'lang': lang}), 'PATCH');
  callApi(url, req, onSuccess, (err) => {
    const msg = `translating messages: ${err}`;
    console.error(msg);
    onError(msg);
  });
}

export function uploadImage(image, onSuccess, onError) {
  const reader = new FileReader();
  reader.readAsArrayBuffer(image);
  reader.onload = () => {
    const id = uuid().replace(/-/g, '');
    const ext = image.type.replace('image/', '');
    const url = `${apiDomain()}/api/image/${id}.${ext}`;
    const req = apiRequest(reader.result, 'POST', image.type);
    callApi(url, req, onSuccess, (err) => {
      const msg = `uploading image: ${err}`;
      console.error(msg);
      onError(msg);
    });
  };
  reader.onerror = (err) => {
    const msg = `encoding image: ${err}`;
    console.error(msg);
    onError(err, msg);
  };
}
