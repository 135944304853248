import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import Control from './Control';
import FeedbackModal from './FeedbackModal';
import FontControl from './FontControl';
import LangControl from './LangControl';
import ModeControl from './ModeControl';
import TranslateControl from './TranslateControl';
import AppContext from '../context/AppContext';

export default class ControlGroup extends React.Component {
  constructor(){
    super();
    this.state = {
      showFeedback: false,
    };
  }

  render() {
    return (
      <>
      <FeedbackModal show={this.state.showFeedback} onHide={() => this.setState({showFeedback: false})} image />
      <ButtonGroup className='controls mx-auto'>
        <Control name='Settings' icon='settings_suggest' disabled />
        <Control name='Feedback' icon='feedback' onClick={() => this.setState({showFeedback: true})} disabled={this.context.flags['promptTools']} />
        {/* TODO: add account management page. */}
        <Control name='Account' icon='account_circle' disabled />
        {/* TODO: add admin page. */}
        {/* <Control name='Admin' icon='settings' /> */}
        {/* TODO: implement login/logout. */}
        <Control name='Logout' icon='logout' disabled />
      </ButtonGroup>
      <ButtonGroup className='controls mb-auto mt-2 mx-auto'>
        <LangControl />
        <TranslateControl />
        <FontControl />
        <ModeControl />
      </ButtonGroup>
      </>
    )
  }
}
ControlGroup.contextType = AppContext;
