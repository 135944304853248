import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import AppContext from '../context/AppContext';

// Name, name
const fonts = [
  ['Small', 'small'],
  ['Medium', 'medium'],
  ['Large', 'large']
];

export default class FontControl extends React.Component {
  constructor(){
    super();
    this.state = {
      clicks: 0,
      firstClick: null
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    // If 8 clicks in 3 seconds then toggle dev tools.
    if (!this.state.firstClick) {
      this.setState({
        clicks: 1,
        firstClick: Date.now()
      });
    } else if (Date.now() - this.state.firstClick < 3000) { // 3 seconds
      this.setState({
        clicks: this.state.clicks + 1,
      }, () => {
        if (this.state.clicks === 8) { // 8 clicks
          this.context.toggleDevTools();
          this.setState({
            clicks: 0,
            firstClick: null
          });
        }
      });
    } else {
      // Reset
      this.setState({
        clicks: 0,
        firstClick: null
      });
    }
  }

  render() {
    var font;
    if (this.context.font) {
      for (var i = 0; i < fonts.length; i++) {
        if (fonts[i][1] === this.context.font) {
          font = fonts[i];
          break;
        }
      }
    }
    const items = [];
    for (let i = 0; i < fonts.length; i++) {
      const f = fonts[i];
      items.push(
        <Dropdown.Item className='font-monospace' key={f[1]} onClick={() => this.context.changeSetting('font', f[1])} disabled={f === font}>
          {f[0]}
        </Dropdown.Item>
      );
    }
    var variant = 'outline-light';
    if (document.documentElement.getAttribute('data-bs-theme') === 'light') {
      variant = 'outline-dark';
    }
    return (
      <Dropdown className='btn-group container p-0'>
        <Dropdown.Toggle variant={`${variant} control p-1`} disabled={this.props.disabled} onClickCapture={this.handleClick}>
          <span className={`font-icon material-symbols-rounded`}>format_size</span>
          <div className={`font-name font-monospace d-flex justify-content-center`}>{font[0]}</div>
        </Dropdown.Toggle>
        <Dropdown.Menu >
          {items}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
FontControl.contextType = AppContext;
