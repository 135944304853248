import React from 'react';
import AppContext from '../context/AppContext';

export default class Counter extends React.Component {
  render() {
    const minShow = this.props.threshold * this.props.max;
    if (this.props.count < minShow) {
      return <span></span>;
    }
    var className = `counter d-flex font-monospace justify-content-end ${this.context.font} me-2`;
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }
    if (this.props.count < 0.8 * this.props.max) {
      className += ' text-success';
    } else if (this.props.count < 0.95 * this.props.max) {
      className += ' text-warning';
    } else {
      className += ' text-danger';
    }
    return (
      <span className={className}>{this.props.count} / {this.props.max}</span>
    );
  }
}
Counter.contextType = AppContext;
