import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AppContext from '../context/AppContext';
import ImageInput from './ImageInput';

export default class ImageModal extends React.Component {
  constructor(){
    super();
    this.state = { image: null };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleCancel() {
    this.imageInput.cancel();
    this.setState({image: null})
    this.props.onHide();
  }

  handleUpload() {
    this.context.sendImage(this.state.image);
    this.handleCancel();
  }

  render() {
    return (
      <Modal centered show={this.props.show} onHide={this.handleCancel}>
        <Modal.Header className='content medium user-select-none' closeButton>
          <Modal.Title className='font-monospace text-primary'>
            <span className='default-icon material-symbols-rounded medium align-middle me-2'>image</span>
            <span className='align-middle'>Upload Image</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageInput imgClass='upload' rounded onImage={(img) => this.setState({image: img})} ref={ref => this.imageInput = ref} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant={`secondary content ${this.context.font} font-monospace`} onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button variant={`primary content ${this.context.font} font-monospace`} disabled={this.state.image === null} onClick={this.handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
ImageModal.contextType = AppContext;
