import React from 'react';
import AppContext from '../context/AppContext';

export default class Control extends React.Component {
  render() {
    var variant;
    if (this.props.variant) {
      variant = `btn-outline-${this.props.variant}`;
    } else {
      variant = 'btn-outline-light';
      if (document.documentElement.getAttribute('data-bs-theme') === 'light') {
        variant = 'btn-outline-dark';
      }
    }
    var iconClass = 'icon medium';
    if (this.props.iconClass) {
      iconClass = this.props.iconClass;
    }
    return (
      <button type='button' className={`control btn ${variant} p-1`} disabled={this.props.disabled} onClick={this.props.onClick}
        onClickCapture={this.props.onClickCapture}>
        <span className={`${iconClass} material-symbols-rounded`}>{this.props.icon}</span>
        <span className={`name font-monospace d-flex justify-content-center large`}>{this.props.name}</span>
      </button>
    )
  }
}
Control.contextType = AppContext;
