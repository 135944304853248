import React from 'react';
import AppContext from '../context/AppContext';

export default class Avatar extends React.Component {
  render() {
    return (
      <div className='avatar me-auto'>
        <span className={`icon material-symbols-rounded text-warning user-select-none medium`}>
          support_agent
        </span>
        {/* <div className={`name font-monospace text-warning user-select-none medium`}>
          Tracer
        </div> */}
      </div>
    )
  }
}
Avatar.contextType = AppContext;
