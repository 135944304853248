import React from 'react';
import Button from 'react-bootstrap/Button';
import AppContext from '../context/AppContext';
import ScrollButton from './ScrollButton';

export default class Terms extends React.Component {
  render() {
    return (
      <>
      <div className='fixed-bottom'>
        <ScrollButton reverse />
      </div>
      <div className={`terms content font-monospace user-select-none ${this.context.font}`}>
        <h2 className='text-center ms-4 pt-1'>
          Terms of Use
          <Button as='a' href='/' variant={`primary content ${this.context.font} font-monospace`} className='float-end me-2'>
            Home
          </Button>
        </h2>
        <div className={`content text-start pe-3 ps-3 ${this.context.font}`}>
          <h4>Acceptance of Terms</h4>
          By accessing or using FlavorTrace ("the Service"), you agree to be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, you may not use the Service.
          <h4 className='pt-2'>User Conduct</h4>
          You agree not to use the Service to:
          <ul>
            <li>Harass, abuse, or harm another person.</li>
            <li>Post or share content that is illegal, harmful, or offensive.</li>
            <li>Spam or send unsolicited messages.</li>
            <li>Violate any applicable laws or regulations.</li>
          </ul>
          <h4 className='pt-2'>Account Registration</h4>
          To use certain features of the Service, you may need to create an account. You agree to:
          <ul>
            <li>Provide accurate and complete information during registration.</li>
            <li>Keep your account information up-to-date.</li>
            <li>Protect the confidentiality of your account credentials.</li>
          </ul>
          <h4 className='pt-2'>Intellectual Property</h4>
          All content, trademarks, and other intellectual property in the Service are owned by the developers of FlavorTrace ("the Developers") or their licensors. You may not use, reproduce, or distribute any content from the Service without our permission.
          <h4 className='pt-2'>User-Generated Content</h4>
          You retain ownership of any content you post or share in the Service. By posting content, you grant the Developers a non-exclusive, royalty-free, worldwide license to use, reproduce, and distribute your content in connection with the Service.
          <h4 className='pt-2'>Privacy and Data Collection</h4>
          We collect and use your data in accordance with our Privacy Policy, which is incorporated into these Terms. Please review the Privacy Policy to understand our practices.
          <h4 className='pt-2'>Interaction with AI Chatbot</h4>
          By using the Service, you acknowledge that you are interacting with an AI chatbot and not with other humans. The AI chatbot may collect and process your chat data to provide responses and improve the service. You must explicitly consent to interact with the AI chatbot during your initial use. Please note that the AI chatbot may make mistakes. Always use your judgment and seek professional advice if needed.
          <h4 className='pt-2'>Use of Data for AI and Data Analytics</h4>
          By using the Service, you agree that the Developers may collect and use your chat data, including text, images, and other content, for the purpose of training and improving our artificial intelligence (AI) systems and data analytics models. Furthermore, you agree that anonymized data sets derived from your content may be licensed to third-party companies for their own AI and data analytics purposes. This usage is in accordance with our Privacy Policy. If you do not agree to this use, please refrain from using the Service.
          <h4 className='pt-2'>Termination</h4>
          We reserve the right to suspend or terminate your account if you violate these Terms or engage in any conduct that we determine to be inappropriate or harmful.
          <h4 className='pt-2'>Limitation of Liability</h4>
          The Service is provided "as is" and without warranties of any kind. The Developers are not liable for any damages arising from your use of the Service.
          <h4 className='pt-2'>Modification of Terms</h4>
          We may modify these Terms at any time. We will notify you of any changes, and your continued use of the Service constitutes acceptance of the updated Terms.
          <h4 className='pt-2'>Governing Law</h4>
          These Terms are governed by and construed in accordance with the laws of the United States. Any disputes arising from these Terms will be resolved in the courts of the United States.
          <h4 className='pt-2'>Contact Information</h4>
          If you have any questions about these Terms, please contact us at <b className='text-info'>flavortrace@gmail.com</b>.
        </div>
      </div>
      </>
    )
  }
}
Terms.contextType = AppContext;
