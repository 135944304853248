import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Control from './Control';
import NoticeModal from './NoticeModal';
import AppContext from '../context/AppContext';

export default class DevControlGroup extends React.Component {
  constructor() {
    super();
    this.state = {
      chatId: null,
      clicks: 0,
      firstClick: null,
      flagCur: '',
      flagKey: null,
      flagNew: null,
      showCopy: false,
      showDelete: false,
      showFlags: false,
      showId: false,
      showPlus: false,
      showReset: false,
      userId: null,
      whichId: null,
    };
    this.createFlagsForm = this.createFlagsForm.bind(this);
    this.createIdForm = this.createIdForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleFlagChange = this.handleFlagChange.bind(this);
    this.handleFlagClear = this.handleFlagClear.bind(this);
    this.handleFlagUpdate = this.handleFlagUpdate.bind(this);
    this.handleIdUpdate = this.handleIdUpdate.bind(this);
  }

  createFlagsForm() {
    return (
      <>
      <InputGroup className='font-monospace'>
        <InputGroup.Text>
          Key
        </InputGroup.Text>
        <Form.Control onChange={(e) => this.handleFlagChange(e.target.value)} />
      </InputGroup>
      <InputGroup className='font-monospace mt-1'>
        <InputGroup.Text>
          Cur
        </InputGroup.Text>
        <Form.Control value={this.state.flagCur} readOnly onClick={(e) => e.target.select()} />
      </InputGroup>
      <InputGroup className='font-monospace mt-1'>
        <InputGroup.Text>
          New
        </InputGroup.Text>
        <Form.Control onChange={(e) => this.handleChange('flagNew', e.target.value)} disabled={!this.state.flagKey}
          ref={ref => this.flagNew = ref}  />
      </InputGroup>
      </>
    );
  }

  createIdForm(type) {
    if (!type) {
      return;
    }
    const value = this.context[type];
    return (
      <>
      <InputGroup className='font-monospace'>
        <InputGroup.Text>
          Cur
        </InputGroup.Text>
        <Form.Control value={value} readOnly onClick={(e) => e.target.select()} />
      </InputGroup>
      <InputGroup className='font-monospace mt-1'>
        <InputGroup.Text>
          New
        </InputGroup.Text>
        <Form.Control onChange={(e) => this.handleChange(type, e.target.value)} />
      </InputGroup>
      </>
    );
  }

  handleChange(key, value) {
    this.setState({[key]: value.trim()});
  }

  handleClick() {
    // If 8 clicks in 8 seconds then toggle dev plus tools.
    console.log("got click " + this.state.clicks)
    if (!this.state.firstClick) {
      this.setState({
        clicks: 1,
        firstClick: Date.now()
      });
    } else if (Date.now() - this.state.firstClick < 8000) { // 8 seconds
      this.setState({
        clicks: this.state.clicks + 1,
      }, () => {
        if (this.state.clicks === 8) { // 8 clicks
          this.setState({
            showPlus: !this.state.showPlus,
            clicks: 0,
            firstClick: null
          });
        }
      });
    } else {
      // Reset
      this.setState({
        clicks: 0,
        firstClick: null
      });
    }
  }

  handleCopy() {
    this.context.copyUser();
    this.setState({showCopy: false});
  }

  handleDelete() {
    this.context.deleteUser();
    this.setState({showDelete: false});
  }

  handleFlagChange(key) {
    key = key.trim();
    this.setState({
      flagKey: key,
      flagCur: key in this.context.flags ? this.context.flags[key] : '',
    });
  }

  handleFlagClear() {
    this.context.setFlag(this.state.flagKey, null, () => {
      this.handleFlagChange(this.state.flagKey);
      this.setState({flagNew: ''})
      this.flagNew.value = '';
    })
  }

  handleFlagUpdate(value) {
    value = value !== null ? this.state.flagNew : null;
    this.context.setFlag(this.state.flagKey, value, () => {
      this.handleFlagChange(this.state.flagKey);
      this.setState({flagNew: ''})
      this.flagNew.value = '';
    })
  }

  handleIdUpdate(key) {
    const value = this.state[key];
    if (key === 'userId') {
      this.context.setUserId(value);
    } else {
      this.context.setChatId(value);
    }
    this.setState({[key]: null, showId: false}, () => { window.location.href = '/'; });
  }

  handleReset() {
    this.context.resetStorage();
    this.setState({showReset: false}, () => { window.location.href = '/'; });
  }

  render() {
    const copyNotice = 'You are about to copy all data from the current user to a new user then load that user. Would you like to proceed?';
    const deleteNotice = 'You are about to delete the current user and load the demo user. Are you sure you want to proceed?';
    const resetNotice = 'You are about to clear all local data and initialize a new user and chat. Are you sure you want to proceed?';
    return (
      <div className={`tools dev mt-auto ${this.context.flags['devTools'] ? '' : 'd-none'}`}>
        <NoticeModal body={copyNotice} icon='content_copy' title='Copy' show={this.state.showCopy} cancel okText='Yes'
          onHide={() => this.setState({showCopy: false})} onOk={this.handleCopy} />
        <NoticeModal body={deleteNotice} icon='delete' title='Delete' show={this.state.showDelete} cancel okText='Yes'
          onHide={() => this.setState({showDelete: false})} onOk={this.handleDelete} />
        <NoticeModal body={resetNotice} icon='history' title='Reset' show={this.state.showReset} cancel okText='Yes'
          onHide={() => this.setState({showReset: false})} onOk={this.handleReset} />
        <NoticeModal body={this.createFlagsForm()} icon='content_copy' title='Flags' show={this.state.showFlags} okText='Update'
          onOk={this.handleFlagUpdate} onHide={() => this.setState({flagCur: '', flagKey: null, flagNew: null, showFlags: false})}
          cancelText='Reset' cancelVariant='danger' cancelDisabled={!this.state.flagCur} okDisabled={!this.state.flagNew}
          onCancel={() => this.handleFlagUpdate(null)} />
        <NoticeModal body={this.createIdForm(this.state.whichId)} icon={this.state.whichId === 'userId' ? 'person' : 'chat'}
          title={this.state.whichId === 'userId' ? 'User ID' : 'Chat ID'} show={this.state.showId} cancel okText='Update'
          onHide={() => this.setState({ [this.state.whichId]: null, showId: false })} onOk={() => this.handleIdUpdate(this.state.whichId)}
          okDisabled={!this.state[this.state.whichId]} />
        <div className='title font-monospace text-info'>
          Developer Tools
        </div>
        <ButtonGroup className='d-flex justify-content-center'>
          <Control name='Undo' icon='undo' variant='info' onClick={this.context.deleteMessage}
            disabled={this.context.messages && this.context.messages.length <= 1}/>
          <Control name='Process' icon='smart_toy' variant='info' onClick={this.context.processMessages}
            disabled={!(this.context.messages && this.context.messages.length > 0 && this.context.messages.at(-1).role === 'user')} />
          <Control name='User ID' icon='person' variant='info' onClick={() => this.setState({showId: true, whichId: 'userId'})}
            onClickCapture={this.handleClick} />
          <Control name='Chat ID' icon='chat' variant='info' onClick={() => this.setState({showId: true, whichId: 'chatId'})} />
        </ButtonGroup>
        <ButtonGroup className={`${this.state.showPlus ? 'd-flex' : 'd-none'} justify-content-center mt-2`}>
          <Control name='Flags' icon='flag' variant='info'  onClick={() => this.setState({showFlags: true})} />
          <Control name='Reset' icon='history' variant='info' onClick={() => this.setState({showReset: true})} />
          <Control name='Copy' icon='content_copy' variant='info' onClick={() => this.setState({showCopy: true})} />
          <Control name='Delete' icon='delete' variant='info' onClick={() => this.setState({showDelete: true})} />
        </ButtonGroup>
      </div>
    )
  }
}
DevControlGroup.contextType = AppContext;
